.table-switch {
  display: flex;
  align-items: center;
  justify-content: center;
}
.table-switch .el-switch__core {
  width: 40px !important;
}
.table-switch .el-switch__core:after {
  background-color: #fff;
}
.table-switch span {
  line-height: 20px;
  margin-left: 5px;
}
.dialog-switch .el-switch__core {
  width: 40px !important;
}
.dialog-switch .el-switch__core:after {
  background-color: #fff;
}
.el-form-item__content .hide /deep/ .el-upload--picture-card {
  display: none;
}
